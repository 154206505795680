import { CanActivateFn, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { inject } from '@angular/core';
import { ProtectionService } from '@services/core-services/protection.service';

export const storeSlugGuard: CanActivateFn = (
  route,
  state
): Observable<boolean> | Promise<boolean> | boolean => {
  const protectionService = inject(ProtectionService);
  const router = inject(Router);
  const storeSlug: any = route.paramMap.get('store_slug');

  if (storeSlug) return true;

  return protectionService.getStore().pipe(
    map(store => {
      const url = `${store!.slug}${state.url}`;
      router.navigateByUrl(url, { replaceUrl: true });
      return false;
    })
  );
};
